var sound=null;
var global = {

    init: function(){
        // request permission on page load


        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $("img.lazy-load").unveil(20, function() {
            $(this).load(function() {
                this.style.opacity = 1;
            });
        });

        $('select.material-select').material_select();

        if($('.credit-remaining').length>0) {
            window.setTimeout(function () {
                global.loadRemainningCredit('/app/user/load-remaining-credit', '.credit-remaining>span');
            }, 200);
        }


        $('.tooltipped').tooltip({delay: 50, html:true});

        $(document).on("click",".alert .close",function(){
            global.hideAlert();
        });

        var isAlertDisplay =  $(".alert.global-alert").length;
        if(isAlertDisplay > 0){
            this.handle = window.setInterval("global.hideAlert();", 7000);
        }

        /*$('.button-collapse').sideNav({
                menuWidth: 260, // Default is 240
                edge: 'left', // Choose the horizontal origin
                closeOnClick: true // Closes side-nav on <a> clicks, useful for Angular/Meteor
            }
        );




        $('#chat-sidebar').sideNav({
                menuWidth: 300, // Default is 240
                edge: 'right', // Choose the horizontal origin
                closeOnClick: true // Closes side-nav on <a> clicks, useful for Angular/Meteor
            }
        );*/

        /*$.extend($.fn.pickadate.defaults, {
            monthsFull: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
            weekdaysShort: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
            today: 'aujourd\'hui',
            clear: 'effacer',
            formatSubmit: 'yyyy/mm/dd'
        })*/


        $(document).on('submit', 'form#form-auth', function() {
            $('.btn-auth').attr('disabled','disabled');
            $('.btn-auth').addClass('disabled');
        });


        $(document).on('click', '#toast-container .toast', function() {
            $(this).fadeOut(function(){
                $(this).remove();
            });
        });


        if($("body").hasClass('front-page')) {
            $(document).euCookieLawPopup().init({
                cookiePolicyUrl: 'https://www.cnil.fr/fr/cookies-traceurs-que-dit-la-loi',
                popupPosition: 'bottomleft',
                colorStyle: 'default',
                compactStyle: true,
                popupTitle: 'MySMS utilise les cookies',
                popupText: 'Nous utilisons des cookies pour vous garantir la meilleure expérience sur notre site. Si vous continuez à utiliser ce dernier, nous considérerons que vous acceptez l\'utilisation des cookies.',
                buttonContinueTitle: "Ok",
                buttonLearnmoreTitle: 'En savoir plus',
                buttonLearnmoreOpenInNewWindow: true,
                agreementExpiresInDays: 30,
                autoAcceptCookiePolicy: true,
                htmlMarkup: null
            });
        }

        if($("#sidebar-nav-scroll").length==1) {
            $('#sidebar-nav-scroll').slimScroll({'height': $(window).height() - 70});
        }
    },
    hideAlert: function(self){
        $(".alert.global-alert").animate({ "top": "-=90px" }, "fast" );
        // $(".alert.abs").slideUp();
        window.clearInterval(this.handle);

    },
    loadRemainningCredit: function(url, elmt){


        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            error: function (resultat, statut, erreur) {
                console.log(resultat);
            },
            success: function (reponse, statut) {

                if(reponse.success){

                    $(elmt).addClass('animated fadeIn').html(reponse.credit);


                    var details = reponse.details;
                    $('.remaining-tts').html(details.tts);
                    $('.remaining-message').html(details.voice);


                }else{
                    if(typeof reponse.redirectTo !='undefined' && reponse.redirectTo){
                        window.show_msg_beforeunload = false;
                        window.top.location.href = reponse.redirectTo;
                    }
                }

            }
        });



    }
}
/**
 * init js
 */
$(document).ready(function() {
    global.init();
});